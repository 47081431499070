<template>
    <div>
      <vs-prompt
      :active.sync="dlgActive"
      title="OTP - Einmalpasswort"
      :buttons-hidden="true"
      id="promptEditTotp"
      style="z-index:29000;">
       <div>
          
            
          <div v-if="!twofactorEnabled" class="d-flex align-items-center">
            <p>{{$t('MAINSETTINGS.OTP.INTRO_TEXT')}}</p>
          </div>
          <div v-if="!twofactorEnabled" class="default-input d-flex align-items-center">
            <vs-button @click="EnableTotp()" class="ml-2" size="small" color="primary" type="filled" icon="lock">{{$t('MAINSETTINGS.ENABLE.OTP')}}</vs-button>
          </div>

          <p v-if="twofactorEnabled && !twofactorActive" class="mt-3">{{$t('MAINSETTINGS.OTP.VALIDATION_TEXT')}}</p>
          <p v-if="twofactorActive" class="mt-3">{{$t('MAINSETTINGS.OTP.ACTIVE')}}</p>
          <div class="d-flex align-items-center">  
            <img v-if="twofactorEnabled && !twofactorActive"  v-bind:src="qrCode"/>
            <img v-if="twofactorActive" :src="require('@/assets/images/symbols/QR_active.png')"/>
          </div>
          <div v-if="twofactorEnabled && !twofactorActive"  class="d-flex align-items-center">  
            <span>{{token}}</span>
          </div>


          <div v-if="twofactorEnabled && !twofactorActive" class="d-flex inputx mb-3 mt-3 align-items-center">  
            <vs-input class="default-input" v-model="code_for_validation"/>
            <vs-button @click="ValidateTotp()" class="ml-2" size="small" color="primary" type="filled">{{$t('MAINSETTINGS.ACTIVATE.OTP')}}</vs-button>
          </div>
          <div class="d-flex align-items-center">  
            <vs-button v-if="twofactorEnabled" @click="OpenConfirmDisableTotp()" class="mt-3" size="small" color="primary" type="border" icon="clear">{{$t('MAINSETTINGS.DISABLE.OTP')}}</vs-button>
          </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import codeHelper from '../../../helper/staticCodeHelper';
export default {
  name: "EditTotp",
  components: {

  },
    data: function (){
      return {
      qrCode:"",
      code_for_validation:"",
      dlgActive:false,
      token:""
      };
  },
  mounted () {
    },

  computed: 
  { 
    twofactorEnabled(){
      return this.$store.state.totp.twofactor.enabled;
    },
    twofactorActive(){
      return this.$store.state.totp.twofactor.active;
    }
  },
  watch: {

      twofactorEnabled(value)
      {
        if(value == true)
        {
          this.getTotpQrCode();
        }
      }
    },
  methods: {
    ...codeHelper,
      ShowDlg(){
        this.dlgActive = true;
        this.LoadData();
      },
      EnableTotp(){
        this.$store.dispatch('totp/enableTotp', { root: true },{ root: true });
      },

      OpenConfirmDisableTotp() {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MAINSETTINGS.QUESTION.DISABLE_OTP'),
          accept: this.DisableTotp
        });
      
      },
      DisableTotp: function()
      {
        this.$store.dispatch('totp/disableTotp', { root: true },{ root: true })
        .then(response => {
          if(response == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.SUCCESS.DISABLE')});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('MAINSETTINGS.ERROR.DISABLE')}); 
          }
          this.LoadData();
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.ERROR.DISABLE').replace("*1*",error)});  
            this.LoadData();
        });
      },    
      LoadData: function ()
      {
        this.$store.dispatch('totp/getTotp', { root: true },{ root: true });
      },
      getTotpQrCode(){

        this.$store.dispatch('totp/getTotpQrCode', { root: true },{ root: true })
        .then(response => {

          if(this.IsNotNull(response))
          {
            this.qrCode = response.txt1;
            this.token = response.txt2;
          }
        
        });
      },
      ValidateTotp(){
        this.$store.dispatch('totp/validateCode', this.code_for_validation)
        .then(response => {
          this.code_for_validation = "";
          if(response == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.SUCCESS.OTP_ACTIVE')}); 
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.ERROR.OTP_ACTIVE')}); 
          }
          this.LoadData();
        });
      }
  }
};

</script>
<style>
div#promptEditTotp > div.vs-dialog{
  max-width: 450px !important;
}


</style>